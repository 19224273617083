import { lazy } from 'react';
// CUSTOM COMPONENTS
import Loadable from '../routes/Loadable';


const NotFoundPage = Loadable(lazy(() => import('pages/404')));
const ComingSoon = Loadable(lazy(() => import('pages/coming-soon')));



export const PublicRoutes = [

  {
    path: '/',
    element: <ComingSoon />
  }, 

  // 404 Page
  {
    path: '*',
    element: <NotFoundPage />
  },


];