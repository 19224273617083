import { createContext, useEffect, useReducer, useCallback } from 'react';
import axios from 'axios';
// CUSTOM LOADING COMPONENT
import { LoadingProgress } from 'components/loader';

import { getSession , postData , setSession , deleteSession  } from 'api-handlers/apiFunctions';



const API_URL = process.env.REACT_APP_AUTH_API_URL
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      {
        let objVals =  {
          isInitialized: true,
          user: action.payload.user,
          isAuthenticated: action.payload.isAuthenticated
        };

        // console.log( { objVals , action  , state } )
        return {
          isInitialized: true,
          user: action.payload.user,
          isAuthenticated: action.payload.isAuthenticated
        };


      }
    case 'LOGIN':
      {
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload.user
        };
      }
    case 'LOGOUT':
      {
        return {
          ...state,
          user: null,
          isAuthenticated: false
        };
      }
    case 'REGISTER':
      {
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload.user
        };
      }
    default:
      {
        return state;
      }
  }
};
export const AuthContext = createContext({});
export const AuthProvider = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // USER LOGIN HANDLER
  const login = useCallback(async (email, password) => {
    let payload = { email, password }

    try{
      let result = await postData( process.env.REACT_APP_LOGIN_ENDPOINT , payload )
      if (result.data){
        setSession( process.env.REACT_APP_AUTH_TOKEN_NAME , result.data.token);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: result.data.user
          }
        });
      }
    }
    catch(error){
        // show some popup here
    }

  }, []);


  // USER REGISTER HANDLER
  const register = useCallback(async (name, email, password) => {
    const {
      data
    } = await axios.post(`${API_URL}/users`, {
      name,
      email,
      password
    });
    setSession(process.env.REACT_APP_AUTH_TOKEN_NAME , data.token);
    dispatch({
      type: 'REGISTER',
      payload: {
        user: data
      }
    });
  }, []);

  // USER LOGOUT HANDLER
  const logout = () => {
    deleteSession( process.env.REACT_APP_AUTH_TOKEN_NAME )
    deleteSession( "userInfo")
    dispatch({
      type: 'LOGOUT'
    });
  };



  useEffect(() => {
    (async () => {
      try {
        const accessToken = getSession(process.env.REACT_APP_AUTH_TOKEN_NAME);

        if (accessToken) {
          let data = { "first_name"  : "something" }

          dispatch({
            type: 'INIT',
            payload: {
              user: data,
              isAuthenticated: true,
              from : "if access token true"

            }
          });
        } 
        else {
          dispatch({
            type: 'INIT',
            payload: {
              user: null,
              isAuthenticated: false,
              isInitialized : false,
              from : "from if access token false"

            }
          });
        }
      } catch (err) {
        console.error("ERROR :> " , {err});
        dispatch({
          type: 'INIT',
          payload: {
            user: null,
            isAuthenticated: false,
            from : "catch"
          }
        });
      }
    })();



  }, []);

  // show loading until not initialized
  if (!state.isInitialized) return <LoadingProgress />;
  // console.log({state})
  return <AuthContext.Provider value={{
    ...state,
    method: 'JWT',
    login,
    register,
    logout
  }}>
      {children}
    </AuthContext.Provider>;
};