import { lazy } from 'react';
import Loadable from './Loadable';
import { GuestGuard } from 'components/auth';

// AUTHENTICATION RELATED PAGES
const LoginDemoWithJWT = Loadable(lazy(() => import('pages/auth/login')));


export const AuthRoutes = [


  {
    element: <GuestGuard />,
    children: [
      {
        path: 'login',
        element: <LoginDemoWithJWT />
      },
  ]
  },

];