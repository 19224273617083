// CUSTOM ICON COMPONENT
import duotone from 'icons/duotone';
export const navigation = [
  {
    name: 'Dashboards',
    Icon: duotone.PersonChalkboard,
    children: [
      {
        name: 'Home',
        path: '/dashboard'
      },
    ]
  },
  {
    name: 'Users & Roles',
    Icon: duotone.UserList,
    children: [
      {
        name: 'Users',
        path: '/dashboard/users'
      },
      {
        name: 'Add User',
        path: '/dashboard/add-user'
      },
      {
        name: 'Roles',
        path: '/dashboard/roles'
      },
      {
        name: 'Add Role',
        path: '/dashboard/add-role'
      },

    ]
  },
  {
    name: 'Scrapers & Jobs',
    Icon: duotone.Invoice,
    children: [
      {
        name: 'Scrapers',
        path: '/dashboard/scrapers'
      }
    ]
  },

  {
    name: 'Subscriptions',
    Icon: duotone.Pricing,
    children: [
      {
        name: 'Subscriptions',
        path: '/dashboard/subscriptions'
      }
    ]
  },

];