import { lazy } from 'react';
import Loadable from './Loadable';
import { AuthRoutes } from './auth';
import { DashboardRoutes } from './dashboard';

// LANDING / INITIAL PAGE
const Landing = Loadable(lazy(() => import('pages/landing')));
const NotFoundPage = Loadable(lazy(() => import('pages/404')));
const HealthCheck = Loadable(lazy(() => import('pages/health')));
const ContactUs = Loadable(lazy(() => import('pages/contact-us')));



export const routes = () => {
  return [
  // INITIAL / INDEX PAGE
  {
    path: '/',
    element: <Landing />
  },

  {
    path: '/health',
    element: <HealthCheck />
  },

  {
    path: '/contact-us',
    element: <ContactUs />
  },

  // 404 Page
  {
    path: '*',
    element: <NotFoundPage />
  },

  ...AuthRoutes,
  ...DashboardRoutes,

];
};